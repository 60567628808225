import { ChainId, Token } from '@meme-dex/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { CHAIN_ID } from './networks'
import { SerializedToken } from './types'
import addresses from './addresses.json'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

const defineTokens = <T extends TokenList>(t: T) => t

export const mainnetTokens = {
  eth: new Token(MAINNET, addresses[MAINNET].WETH, 18, 'APE', 'APE', 'https://apechain.moodeng.fi'),
  cake: new Token(MAINNET, addresses[MAINNET].PlatformToken, 18, 'MDF', 'MoodengFi', 'https://apechain.moodeng.fi'),
  weth: new Token(MAINNET, addresses[MAINNET].WETH, 18, 'wAPE', 'Wrapped APE', 'https://apechain.moodeng.fi'),
  weth2: new Token(MAINNET, '0x5a77f1443d16ee5761d310e38b62f77f726bc71c', 18, 'WETH', 'Wrapped ETH', ''),
  okbaby: new Token(MAINNET, '0xd0034aee8e1d8d8919a401c546ed2d0f2a4c90f1', 18, 'OKBABY', 'OKB BABY', ''),
  okcat: new Token(MAINNET, '0x5574730e54e167e2fd0003aed0d6ce31ed8b4600', 18, 'OKcat', 'OKcat', ''),
  busd: new Token(MAINNET, '0xf58ae68f5f295f4aa4a9d56065efaa5197f2baa9', 18, 'USDT', 'Tether USDT', ''),
  usdc: new Token(MAINNET, '0x1e4a5963abfd975d8c9021ce480b42188849d41d', 6, 'USDT', 'USD Tether', ''),
  usdt: new Token(MAINNET, '0x1e4a5963abfd975d8c9021ce480b42188849d41d', 6, 'USDT', 'USD Tether', ''),
  syrup: new Token(MAINNET, '0xa8d9b7a299248fa20f1c88ca385ba1908cf31ee8', 18, 'ABS', 'AbstraDEX', ''),
}

export const testnetTokens = {
  cake: new Token(TESTNET, addresses[TESTNET].PlatformToken, 18, 'MDF', 'MoodengFi', 'https://apechain.moodeng.fi'),
  weth: new Token(TESTNET, addresses[TESTNET].WETH, 18, 'WAPE', 'Wrapped APE', 'https://apechain.moodeng.fi'),
  busd: new Token(TESTNET, '0xd0034aee8e1d8d8919a401c546ed2d0f2a4c90f1', 18, 'USDC', 'USD coin', ''),
  usdc: new Token(TESTNET, '0xd0034aEe8e1d8d8919A401c546Ed2d0F2A4C90F1', 18, 'USDC', 'USD coin', ''),
  okcat: new Token(TESTNET, '0xd0034aEe8e1d8d8919A401c546Ed2d0F2A4C90F1', 18, 'USDC', 'USD coin', ''),
  usdt: new Token(TESTNET, '0xd0034aEe8e1d8d8919A401c546Ed2d0F2A4C90F1', 18, 'USDT', 'USD Tether', ''),
}

const tokens = () => {
  const chainId = CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(testnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] }
    }, {} as typeof testnetTokens)
    // return Object.keys(mainnetTokens).reduce((accum, key) => {
    //   return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    // }, {} as typeof testnetTokens & typeof mainnetTokens)
  }

  return mainnetTokens
}

const unserializedTokens = tokens()

type SerializedTokenList = Record<keyof typeof unserializedTokens, SerializedToken>

export const serializeTokens = () => {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {} as SerializedTokenList)

  return serializedTokens as any
}

export default unserializedTokens
