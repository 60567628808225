import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 19 18" {...props}>
      <path d="M2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H16C16.55 0 17.021 0.195667 17.413 0.587C17.8043 0.979 18 1.45 18 2V3C18 3.28333 17.904 3.521 17.712 3.713C17.5207 3.90433 17.2833 4 17 4C16.7167 4 16.4793 3.90433 16.288 3.713C16.096 3.521 16 3.28333 16 3V2H2V16H16V15C16 14.7167 16.096 14.4793 16.288 14.288C16.4793 14.096 16.7167 14 17 14C17.2833 14 17.5207 14.096 17.712 14.288C17.904 14.4793 18 14.7167 18 15V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM15.175 10H7C6.71667 10 6.47933 9.904 6.288 9.712C6.096 9.52067 6 9.28333 6 9C6 8.71667 6.096 8.479 6.288 8.287C6.47933 8.09567 6.71667 8 7 8H15.175L13.3 6.1C13.1167 5.91667 13.021 5.68733 13.013 5.412C13.0043 5.13733 13.1 4.9 13.3 4.7C13.4833 4.51667 13.7167 4.425 14 4.425C14.2833 4.425 14.5167 4.51667 14.7 4.7L18.3 8.3C18.5 8.5 18.6 8.73333 18.6 9C18.6 9.26667 18.5 9.5 18.3 9.7L14.7 13.3C14.5167 13.4833 14.2877 13.579 14.013 13.587C13.7377 13.5957 13.5 13.5 13.3 13.3C13.1167 13.1167 13.025 12.8833 13.025 12.6C13.025 12.3167 13.1167 12.0833 13.3 11.9L15.175 10Z" />
    </Svg>
  )
}

export default Icon
