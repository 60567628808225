import React from 'react'
import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'
import Button from '../../components/Button/Button'
import Text from '../../components/Text/Text'
import MoreHorizontal from '../../components/Svg/Icons/MoreHorizontal'
import { ButtonProps } from '../../components/Button'
import { connectorLocalStorageKey, walletConnectConfig, walletLocalStorageKey } from './config'
import { Login, Config } from './types'

interface Props {
  walletConfig: Config
  login: Login
  onDismiss: () => void
  fontSize: string
  fontColor: string
  isMobile?: boolean
}

const WalletButton = styled(Button).attrs({
  width: '100%',
  py: ['6px', , , , '10px'],
  px: ['10px', , , , '16px'],
  variant: 'text',
})`
  background: var(--primary-color);
  align-items: center;
  display: flex;
  gap: 10px;
  height: auto;
  justify-content: flex-start;
  border-radius: 10px;
`

const MoreButton = styled(WalletButton)`
  padding: 0 0 16px 0;
`

const MoreBg = styled.div`
  background: ${({ theme }) => theme.colors.text};
  border-radius: 8px;

  display: flex;
  align-items: center;
  margin-bottom: 4px;
`

interface MoreWalletCardProps extends ButtonProps {
  t: (key: string) => string
}

export const MoreWalletCard: React.FC<MoreWalletCardProps> = ({ t, ...props }) => {
  return (
    <MoreButton variant="tertiary" {...props}>
      <MoreBg>
        <MoreHorizontal width="40px" color="var(--color-text)" />
      </MoreBg>
      <Text fontSize="14px" color="var(--color-black)">
        {t('More')}
      </Text>
    </MoreButton>
  )
}

const WalletCard: React.FC<Props> = ({
  login,
  walletConfig,
  onDismiss,
  fontSize = ['30px'],
  fontColor,
  isMobile,
  ...props
}) => {
  const { title, icon: Icon } = walletConfig
  return (
    <WalletButton
      variant="secondary"
      onClick={() => {
        // TW point to WC on desktop
        if (title === 'Trust Wallet' && walletConnectConfig && isDesktop) {
          login(walletConnectConfig.connectorId)
          localStorage?.setItem(walletLocalStorageKey, walletConnectConfig.title)
          localStorage?.setItem(connectorLocalStorageKey, walletConnectConfig.connectorId)
          onDismiss()
          return
        }
        if (!window.ethereum && walletConfig.href) {
          window.open(walletConfig.href, '_blank', 'noopener noreferrer')
        } else {
          login(walletConfig.connectorId)
          localStorage?.setItem(walletLocalStorageKey, walletConfig.title)
          localStorage?.setItem(connectorLocalStorageKey, walletConfig.connectorId)
          onDismiss()
        }
      }}
      id={`wallet-connect-${title.toLocaleLowerCase()}`}
      {...props}
    >
      <Icon width={isMobile ? '25px' : '30px'} />
      <Text fontSize={fontSize} color={fontColor}>
        {title}
      </Text>
    </WalletButton>
  )
}

export default WalletCard
