/* eslint-disable import/no-useless-path-segments */
import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 67 63" {...props}>
      <path d="M0.162683 0.0390625L25.9245 34.7439L0 62.9605H5.83457L28.5314 38.2565L46.8699 62.9605H66.7252L39.5138 26.3035L63.6442 0.0390625H57.8096L36.9069 22.791L20.0179 0.0390625H0.162683ZM8.74285 4.36911H17.8644L58.1437 58.6297H49.0221L8.74285 4.36911Z" />
    </Svg>
  )
}

export default Icon
