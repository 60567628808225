import throttle from 'lodash/throttle'
import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import BottomNav from '../../components/BottomNav'
import { Box } from '../../components/Box'
import Flex from '../../components/Box/Flex'
// eslint-disable-next-line import/no-cycle
// import Footer from '../../components/Footer'
import MenuItems from '../../components/MenuItems/MenuItems'
import { SubMenuItems } from '../../components/SubMenuItems'
import { useMatchBreakpoints } from '../../hooks'
// import CakePrice from '../../components/CakePrice/CakePrice'
// import Logo from './components/Logo'
import { MENU_HEIGHT, MOBILE_MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from './config'
import { NavProps } from './types'
import { MenuContext } from './context'
import { Text } from '../../components/Text'
import { Link } from '../../components/Link'
import { TwitterIcon, TelegramIcon } from '../../components/Svg'
import { Button } from '../../components/Button'
import useDebounce from '../../../../../hooks/useDebounce'
// import LangSelector from '../../components/LangSelector/LangSelector'

const zoom = keyframes`
  0% {
    transform: scale(0.97);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(0.97);
  }
`

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
  }

  &:before {
    content: '';
    position: fixed;
    z-index: -9;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: url('/images/bg.svg') no-repeat;
    background-size: cover;
    background-position: center;
  }
`

const GradientText = styled(Text).attrs({ textAlign: 'center', fontFamily: 'Modak', fontSize: ['38px', , , , '90px'] })`
  background: linear-gradient(180deg, #d47282 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const Right = styled(Flex).attrs({ width: '100%' })<{ $isActive?: boolean }>`
  max-width: 100%;
  margin: auto;
  position: relative;
  height: ${({ $isActive }) => (!$isActive ? '100vh' : 'auto')};
  transition: all 0.35s linear;
  padding: 16px 0;

  ${({ theme }) => theme.mediaQueries.lg} {
    height: 100vh;
    max-width: ${({ $isActive }) => ($isActive ? '50%' : '100%')};
    margin: 0;
  }
`

const Left = styled(Box).attrs({ width: '100%' })<{ $isActive?: boolean }>`
  background: linear-gradient(180deg, #00f3ff 0%, #c86077 100%);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  position: ${({ $isActive }) => (!$isActive ? 'fixed' : 'relative')};
  height: ${({ $isActive }) => (!$isActive ? '0' : '100%')};
  opacity: ${({ $isActive }) => (!$isActive ? '0' : '1')};
  max-width: 100%;

  transition: height 0.45s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow-y: auto;

  ${({ theme }) => theme.mediaQueries.lg} {
    position: fixed;
    z-index: 9;
    right: 0;
    top: 0;
    bottom: 0;

    max-width: ${({ $isActive }) => (!$isActive ? '0' : '50%')};
    opacity: ${({ $isActive }) => (!$isActive ? '0' : '1')};
    transition: all 0.45s cubic-bezier(0.075, 0.82, 0.165, 1);

    border-bottom-left-radius: 16px;
    border-top-right-radius: 0px;
  }
`

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transform: translate3d(0, 0, 0);
  height: 100%;
`

const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: relative;
  z-index: 1;
  width: 100%;
  display: none;
  flex-direction: row;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: flex;
  }
`

const MobileFixedContainer = styled(FixedContainer)`
  display: flex;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: none;
  }
`

const FlexHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
`

const BodyWrapper = styled(Box)`
  position: relative;
  width: 100%;
  min-height: calc(100vh - ${MENU_HEIGHT}px);
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
`

const DegenButton = styled(Button)`
  animation: ${zoom} 2.05s ease-in-out infinite;
`

const Menu: React.FC<NavProps> = ({
  linkComponent = 'a',
  userMenu,
  banner,
  header,
  globalMenu,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  cakePriceUsd,
  links,
  subLinks,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  buyCakeLabel,
  children,
}) => {
  const { isMobile } = useMatchBreakpoints()
  const [showMenu, setShowMenu] = useState(true)
  const refPrevOffset = useRef(typeof window === 'undefined' ? 0 : window.pageYOffset)
  const [show, setShow] = useState(true)
  const [duration, setDuration] = useState(0)
  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT

  const totalTopMenuHeight = banner ? MENU_HEIGHT : MENU_HEIGHT

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true)
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true)
        } else {
          // Has scroll down
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [totalTopMenuHeight])

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === 'Home')

  const subLinksWithoutMobile = subLinks?.filter((subLink) => !subLink.isMobileOnly)
  const subLinksMobileOnly = subLinks?.filter((subLink) => subLink.isMobileOnly)

  const toggleShow = () => setShow((prev) => !prev)
  const isShow = useDebounce(show, 150)

  return (
    <MenuContext.Provider value={{ linkComponent, isShow }}>
      <Wrapper>
        <Right $isActive={isShow}>
          <Flex margin="auto" maxWidth="490px" width="100%" flexDirection="column" alignItems="center">
            <DegenButton variant="text" width={['75%', , , , '100%']} height="100%" onClick={toggleShow}>
              <img src="/images/meme-logo.svg" alt="meme-logo" />
            </DegenButton>

            <GradientText>MoodengFi</GradientText>

            <Flex justifyContent="center" style={{ gap: '36px' }}>
              <Link href="https://x.com/Moodeng_Fi" external>
                <TwitterIcon width={isMobile ? '28px' : '66px'} />
              </Link>
              <Link href="https://t.me/MoodengFi_Official" external>
                <TelegramIcon width={isMobile ? '32px' : '76px'} />
              </Link>
            </Flex>

            <MobileFixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
              <StyledNav>
                <FlexHeader>{header}</FlexHeader>
              </StyledNav>
            </MobileFixedContainer>
          </Flex>
        </Right>

        <Left $isActive={isShow}>
          <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
            <StyledNav>
              <Box pl="25px">
                <MenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} isShow={isShow} />
              </Box>
              <FlexHeader>{header}</FlexHeader>
            </StyledNav>
          </FixedContainer>
          {/* {subLinks ? (
            <Flex justifyContent="space-around">
              <SubMenuItems items={subLinksWithoutMobile} mt={`${totalTopMenuHeight}px`} activeItem={activeSubItem} />
              {subLinksMobileOnly?.length > 0 && (
                <SubMenuItems items={subLinksMobileOnly} activeItem={activeSubItem} isMobileOnly />
              )}
            </Flex>
          ) : null} */}
          <BodyWrapper>
            <Inner isPushed={false} showMenu={showMenu}>
              {children}
              {/* <Footer
              items={footerLinks}
              isDark={isDark}
              toggleTheme={toggleTheme}
              langs={langs}
              setLang={setLang}
              currentLang={currentLang}
              cakePriceUsd={cakePriceUsd}
              buyCakeLabel={buyCakeLabel}
              mb={[`${MOBILE_MENU_HEIGHT}px`, null, '0px']}
            /> */}
            </Inner>
          </BodyWrapper>
          {isMobile && <BottomNav items={links} activeItem={activeItem} activeSubItem={activeSubItem} />}
        </Left>
      </Wrapper>
    </MenuContext.Provider>
  )
}

export default Menu
