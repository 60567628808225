const variants = {
  warning: {
    background: 'rgba(217, 217, 217, 1)',
    borderColor: 'black',
    borderRadius: '8px',
  },
  danger: {
    background: '#ED4B9E19',
    borderColor: 'failure',
  },
  success: {
    background: 'rgba(49, 208, 170, 0.1)',
    borderColor: 'black',
  },
}

export default variants
