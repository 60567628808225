import { createReducer } from '@reduxjs/toolkit'
import { ETHER } from '@meme-dex/sdk'
import { selectCurrency, typeInput } from './actions'
import { BridgeProxyState } from './types'

const initialState: BridgeProxyState = {
  typedValue: '',
  currencyId: ETHER.symbol,
}

export default createReducer<BridgeProxyState>(initialState, (builder) =>
  builder
    .addCase(selectCurrency, (state, { payload: { currencyId } }) => {
      return {
        ...state,
        currencyId,
      }
    })
    .addCase(typeInput, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        typedValue,
      }
    }),
)
