import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from 'packages/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: rgba(252, 176, 181, 1);
    --color-black: #000;
    --color-white: #fff;
    --modal-bg-color: rgba(255, 255, 255, 1);
    --color-text: rgba(38, 38, 43, 1);
    --color-error: rgba(255, 0, 4, 1);
    --color-success: rgba(92, 118, 15, 1);
    --color-pink: rgba(252, 176, 181, 1);
  }
  * {
    font-family: 'Acme', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
