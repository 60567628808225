/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useContext } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  Flex,
  LogoutIcon,
  // RefreshIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  // UserMenuDivider,
  // UserMenuItem,
  UserMenuVariant,
  Text,
  Button,
  Box,
  MetamaskIcon,
  Link,
  CopyIcon,
  IconButton,
  CloseIcon,
  OpenNewIcon,
} from 'packages/uikit'
import Trans from 'components/Trans'
import useAuth from 'hooks/useAuth'
// import { useRouter } from 'next/router'
import { useProfile } from 'state/profile/hooks'
import { usePendingTransactions } from 'state/transactions/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from 'contexts/Localization'
// import { nftsBaseUrl } from 'views/Nft/market/constants'
import styled from 'styled-components'
import { useMatchBreakpoints } from 'packages/uikit/src/hooks'
import truncateHash from 'utils/truncateHash'
import useTokenBalance, { useGetBnbBalance } from 'hooks/useTokenBalance'
import tokens from 'config/constants/tokens'
import { CurrencyLogo } from 'components/Logo'
import { formatBigNumber, getFullDisplayBalance } from 'utils/formatBalance'
import { ETHER } from '@meme-dex/sdk'
import { getBscScanLink } from 'utils'
import { copyText } from 'utils/copyText'
import { setupNetwork } from 'utils/wallet'
import Explore from 'components/ui/layout/Explore'
import { MenuContext } from 'packages/uikit/src/widgets/Menu/context'
import WalletModal, { WalletView } from './WalletModal'
// import ProfileUserMenuItem from './ProfileUserMenuItem'
// import WalletUserMenuItem from './WalletUserMenuItem'
// import { CopyButton } from '../../CopyButton'

const UserMenu = () => {
  // const router = useRouter()
  const { t } = useTranslation()
  const { account, error, library } = useWeb3React()
  const { logout } = useAuth()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  const { isInitialized, isLoading, profile } = useProfile()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)
  // const hasProfile = isInitialized && !!profile
  const avatarSrc = profile?.nft?.image?.thumbnail
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
  const isWrongNetwork: boolean = error && error instanceof UnsupportedChainIdError
  const { isMobile } = useMatchBreakpoints()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useTokenBalance(tokens.cake.address)
  const { isShow } = useContext(MenuContext)

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  const onClickWalletMenu = (): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentWalletModal()
    }
  }

  const handleSwitchNetwork = async (): Promise<void> => {
    await setupNetwork(library)
  }

  const handleChangeSwitch = async (): Promise<void> => {
    if (isWrongNetwork) {
      await handleSwitchNetwork()

      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }

  const UserMenuItems = ({ handleToggle }) => {
    return (
      <>
        <Flex flexDirection="column" py="16px" px="16px" position="relative">
          <ButtonClose onClick={handleToggle}>
            <CloseIcon width="16px" color="var(--color-black)" />
          </ButtonClose>

          <Flex flexDirection="column" style={{ gap: '0px' }} pt="8px">
            <Flex alignItems="center" mb="12px" style={{ gap: '8px' }}>
              <Text mr="4px">You wallet: {truncateHash(account, 6, 6)}</Text>
              <Button variant="text" padding="0" onClick={() => copyText(account)}>
                <CopyIcon width="14px" color="var(--color-pink)" />
              </Button>
              <Button as={Link} external href={getBscScanLink(account, 'address')} variant="text" padding="0">
                <OpenNewIcon width="16px" color="var(--color-pink)" />
              </Button>
            </Flex>

            <Flex justifyContent="space-between" mb="8px">
              <Flex alignItems="center">
                <CurrencyLogo currency={ETHER} size={['28px']} />
                <Text fontSize="20px">{ETHER.symbol}</Text>
              </Flex>
              <Text fontSize="20px" textAlign="center" ellipsis>
                {formatBigNumber(balance, 6) || 0}{' '}
              </Text>
            </Flex>

            <Flex justifyContent="space-between">
              <Flex alignItems="center">
                <CurrencyLogo currency={tokens.cake} size={['28px']} />
                <Text fontSize="20px">{tokens.cake.symbol}</Text>
              </Flex>
              <Text fontSize="20px" textAlign="center" ellipsis>
                {getFullDisplayBalance(cakeBalance, 18, 2) || 0}{' '}
              </Text>
            </Flex>
          </Flex>
          <Flex style={{ gap: '6px' }}>
            <Button padding="0" width="100%" onClick={logout} style={{ borderRadius: '10px' }}>
              <Text fontSize="16px" color="var(--color-error)" mr="6px">
                {t('Disconnect')}
              </Text>
              <LogoutIcon color="var(--color-error)" width="18px" />
            </Button>
          </Flex>
        </Flex>
      </>
    )
  }

  if (account) {
    return (
      <UIKitUserMenu
        account={account}
        avatarSrc={avatarSrc}
        text={userMenuText}
        variant={userMenuVariable}
        bnbBalance={formatBigNumber(balance, 6)}
        explore={<Explore logout={logout} />}
        isShow={isShow}
      >
        {({ isOpen, handleToggle }) => (isOpen ? <UserMenuItems handleToggle={handleToggle} /> : null)}
      </UIKitUserMenu>
    )
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu
        text={t('Network')}
        variant="danger"
        handleChangeSwitch={handleChangeSwitch}
        isWrongNetwork={isWrongNetwork}
      >
        {({ isOpen, handleToggle }) => (isOpen ? <UserMenuItems handleToggle={handleToggle} /> : null)}
      </UIKitUserMenu>
    )
  }

  return (
    <Box width="100%" maxWidth={['100%', , , , '244px']}>
      <StyledConnectButton>
        <Text>
          <Trans>Connect Wallet</Trans>
        </Text>
      </StyledConnectButton>
    </Box>
  )
}

export default UserMenu

const StyledConnectButton = styled(ConnectWalletButton)`
  width: 100%;
  height: auto;
`

const Wrapper = styled(Flex)`
  position: relative;
  flex-shrink: 0;
  width: 56px;
  margin: auto;

  img {
    &.chain-logo {
      border-radius: 50%;
      border: 1px solid var(--color-black);
    }
  }
`

export const ButtonClose = styled(IconButton).attrs({ variant: 'text' })`
  width: 20px !important;
  height: 20px;
  border-radius: 50px;
  border: 1px solid var(--color-black);

  position: absolute;
  top: 10px;
  right: 10px;
`
