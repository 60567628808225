import { useWeb3React } from '@web3-react/core'
import { Button, CopyIcon, OpenNewIcon, LogoutIcon, Flex, Link } from 'packages/uikit'
import { getBscScanLink } from 'utils'
import { copyText } from 'utils/copyText'

const Explore = ({ logout }) => {
  const { account } = useWeb3React()

  return (
    <>
      <Flex>
        <Button variant="text" padding="0" onClick={() => copyText(account)}>
          <CopyIcon width="14px" color="var(--color-pink)" />
        </Button>
        <Button as={Link} external href={getBscScanLink(account, 'address')} variant="text" padding="0">
          <OpenNewIcon width="16px" color="var(--color-pink)" />
        </Button>
        <Button
          variant="text"
          padding="12px"
          onClick={logout}
          style={{ background: 'var(--primary-color)', borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}
        >
          <LogoutIcon color="var(--color-error)" width="18px" />
        </Button>
      </Flex>
    </>
  )
}

export default Explore
