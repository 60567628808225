import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 35 35" {...props}>
      <path
        d="M17.5 0C7.84 0 0 7.84 0 17.5C0 27.16 7.84 35 17.5 35C27.16 35 35 27.16 35 17.5C35 7.84 27.16 0 17.5 0ZM19.25 26.25H15.75V22.75H19.25V26.25ZM19.25 19.25H15.75V8.75H19.25V19.25Z"
        fill="#FF0000"
      />
    </Svg>
  )
}

export default Icon
