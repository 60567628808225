import React, { useState } from 'react'
import styled from 'styled-components'
// import getExternalLinkProps from '../../util/getExternalLinkProps'
// import Grid from '../../components/Box/Grid'
import Box from '../../components/Box/Box'
import Text from '../../components/Text/Text'
import Heading from '../../components/Heading/Heading'
// import { Button } from '../../components/Button'
import { ModalBody, ModalCloseButton, ModalContainer, ModalHeader, ModalTitle } from '../Modal'
import WalletCard, { MoreWalletCard } from './WalletCard'
import config, { walletLocalStorageKey } from './config'
import { Config, Login } from './types'
import { Flex } from '../../components/Box'
import { useMatchBreakpoints } from '../../hooks'

interface Props {
  login: Login
  onDismiss?: () => void
  displayCount?: number
  t: (key: string) => string
}

const WalletWrapper = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
  }
`

const Line = styled.div`
  background: #bfbfbf;

  width: 100%;
  height: 1px;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 2px;
    height: auto;
  }
`

const StyledModalContainer = styled(ModalContainer)`
  height: auto;
  border: 1px solid black;
  border-radius: 16px;
  box-shadow: 0px 1px 0px 2px rgba(0, 0, 0, 1);

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 392px;
  width: 100%;
`

const WrapperLeft = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: 24px;
  padding: 6px 0px;
`

const GridWalletWrapper = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 8px;
  grid-template-columns: 1fr;
`

const getPriority = (priority: Config['priority']) => (typeof priority === 'function' ? priority() : priority)

/**
 * Checks local storage if we have saved the last wallet the user connected with
 * If we find something we put it at the top of the list
 *
 * @returns sorted config
 */
const getPreferredConfig = (walletConfig: Config[]) => {
  const sortedConfig = walletConfig.sort((a: Config, b: Config) => getPriority(a.priority) - getPriority(b.priority))

  const preferredWalletName = localStorage?.getItem(walletLocalStorageKey)

  if (!preferredWalletName) {
    return sortedConfig
  }

  const preferredWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === preferredWalletName)

  if (!preferredWallet) {
    return sortedConfig
  }

  return [
    preferredWallet,
    ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== preferredWalletName),
  ]
}

const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null, displayCount = 5, t }) => {
  const [showMore, setShowMore] = useState(false)
  const sortedConfig = getPreferredConfig(config)
  // Filter out WalletConnect if user is inside TrustWallet built-in browser
  const walletsToShow = window.ethereum?.isTrust
    ? sortedConfig.filter((wallet) => wallet.title !== 'WalletConnect')
    : sortedConfig
  const displayListConfig = showMore ? walletsToShow : walletsToShow.slice(0, displayCount)
  const { isMobile } = useMatchBreakpoints

  return (
    <StyledModalContainer>
      <ModalHeader>
        <ModalTitle>
          <Text width="100%" fontSize={['16px', , , , '20px']} color="var(--color-black)" textAlign="left">
            {t('Connect a wallet')}
          </Text>
        </ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <ModalBody px="16px" width={['100%', null, 'auto']}>
        <WalletWrapper>
          <WrapperLeft>
            <GridWalletWrapper>
              {displayListConfig.map((wallet) => (
                <Box key={wallet.title}>
                  <WalletCard
                    walletConfig={wallet}
                    login={login}
                    onDismiss={onDismiss}
                    isMobile={isMobile}
                    fontSize={['16px', , , , '20px']}
                    fontColor="#000"
                  />
                </Box>
              ))}
              {/* {!showMore && (
                <Box>
                  <MoreWalletCard t={t} onClick={() => setShowMore(true)} />
                </Box>
              )} */}
            </GridWalletWrapper>
          </WrapperLeft>
        </WalletWrapper>

        <Text fontSize={['16px']} mb="22px">
          By connecting a wallet, you agree to Our Terms of Service and consent to its Privacy Policy
        </Text>
        {/* <WalletWrapper> */}

        {/* 
          <WrapperRight width="50%"></WrapperRight> */}
        {/* {!showMore && <MoreWalletCard t={t} onClick={() => setShowMore(true)} />} */}
        {/* </WalletWrapper> */}

        {/* <Line />

        <Box p="24px">
          <Text textAlign="center" color="var(--color-black)" as="p" mb="16px">
            {t('Please confirm in your wallet')}
          </Text>
          <Button
            as="a"
            href="https://docs.wagyuswap.app/get-started/connection-guide"
            variant="subtle"
            width="100%"
            {...getExternalLinkProps()}
          >
            {t('Learn How to Connect')}
          </Button>
        </Box> */}
      </ModalBody>
    </StyledModalContainer>
  )
}

export default ConnectModal
