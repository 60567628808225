/* eslint-disable import/no-useless-path-segments */
import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 82 67" {...props}>
      <path d="M32.3266 44.1575L30.9904 62.8638C32.9021 62.8638 33.73 62.0464 34.7228 61.0648L43.6852 52.5391L62.2561 66.0764C65.6621 67.9658 68.0617 66.9709 68.9805 62.9576L81.1704 6.10168L81.1738 6.09833C82.2541 1.08675 79.353 -0.872987 76.0346 0.356457L4.3826 27.6622C-0.507501 29.5516 -0.43346 32.265 3.55132 33.4945L21.8699 39.166L64.4202 12.6643C66.4227 11.3444 68.2434 12.0747 66.7458 13.3946L32.3266 44.1575Z" />
    </Svg>
  )
}

export default Icon
