import React, { useContext } from 'react'
import { MenuContext } from '../../widgets/Menu/context'
import { Flex } from '../Box'
import AnimatedIconComponent from '../Svg/AnimatedIconComponent'
import { StyledBottomNavItem, StyledBottomNavText } from './styles'
import { BottomNavItemProps } from './types'

const BottomNavItem: React.FC<BottomNavItemProps> = ({
  label,
  icon,
  fillIcon,
  href,
  showItemsOnMobile = false,
  isActive = false,
  ...props
}) => {
  const { linkComponent } = useContext(MenuContext)

  const bottomNavItemContent = (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%">
      {icon && (
        <AnimatedIconComponent
          icon={icon}
          fillIcon={fillIcon}
          height="22px"
          width="21px"
          color={isActive ? 'secondary' : 'textSubtle'}
          isActive={isActive}
          activeBackgroundColor="textActive"
        />
      )}
      <StyledBottomNavText color={isActive ? 'rgba(204, 88, 96, 1)' : 'var(--color-text)'}>{label}</StyledBottomNavText>
    </Flex>
  )

  return showItemsOnMobile ? (
    <StyledBottomNavItem as={linkComponent} href={href} {...props}>
      {bottomNavItemContent}
    </StyledBottomNavItem>
  ) : (
    <StyledBottomNavItem as={linkComponent} href={href} {...props}>
      {bottomNavItemContent}
    </StyledBottomNavItem>
  )
}

export default BottomNavItem
