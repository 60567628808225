/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, memo } from 'react'
import styled from 'styled-components'
// import { useMatchBreakpoints } from '../../hooks'
import { Flex } from '../Box'
import { Text } from '../Text'
import isTouchDevice from '../../util/isTouchDevice'
import DropdownMenu from '../DropdownMenu/DropdownMenu'
import MenuItem from '../MenuItem/MenuItem'
import { MenuItemsProps } from './types'
import { ChevronDownIcon } from '../Svg'

const MenuItems: React.FC<MenuItemsProps> = ({ items = [], activeItem, activeSubItem, isShow, ...props }) => {
  return (
    <Flex {...props} alignItems="center" justifyContent="flex-start" width="100%" style={{ gap: '16px' }}>
      {items.map(({ label, items: menuItems = [], href, icon }) => {
        const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color
        const isActive = activeItem === href
        const linkProps = isTouchDevice() && menuItems && menuItems.length > 0 ? {} : { href }
        const Icon = icon
        return (
          <DropdownMenu key={`${label}#${href}`} items={menuItems} py={1} activeItem={activeSubItem} isShow={isShow}>
            <MenuItem {...linkProps} isActive={isActive} statusColor={statusColor}>
              {icon && createElement(Icon as any, { width: '26px', height: '26px', color: 'none' })}
              <Text>{label}</Text>
              {menuItems.length > 0 ? <ChevronDownIcon color="var(--color-black)" /> : null}
            </MenuItem>
          </DropdownMenu>
        )
      })}
    </Flex>
  )
}

export default memo(MenuItems)
