import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 12 14" {...props}>
      <path d="M8.8421 0H1.26316C0.568421 0 0 0.572727 0 1.27273V10.1818H1.26316V1.27273H8.8421V0ZM10.7368 2.54545H3.78947C3.09474 2.54545 2.52632 3.11818 2.52632 3.81818V12.7273C2.52632 13.4273 3.09474 14 3.78947 14H10.7368C11.4316 14 12 13.4273 12 12.7273V3.81818C12 3.11818 11.4316 2.54545 10.7368 2.54545ZM10.7368 12.7273H3.78947V3.81818H10.7368V12.7273Z" />
    </Svg>
  )
}

export default Icon
