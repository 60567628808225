import styled from 'styled-components'
import { Flex, Text, Link, Box, Skeleton } from 'packages/uikit'
import { useWeb3React } from '@web3-react/core'
import { FetchStatus } from 'config/constants/types'
import { CurrencyLogo } from 'components/Logo'
import { ETHER } from '@meme-dex/sdk'
import tokens from 'config/constants/tokens'
import { formatBigNumber, getFullDisplayBalance } from 'utils/formatBalance'
import useTokenBalance, { useGetBnbBalance } from 'hooks/useTokenBalance'
import UserMenu from '../../Menu/UserMenu'
import SelectNetwork from './SelectNetwork'

export const Header = () => {
  const { balance, fetchStatus } = useGetBnbBalance()
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useTokenBalance(tokens.cake.address)
  const { account } = useWeb3React()

  return (
    <MainHeader>
      <HeaderButtons>
        <Flex
          width={['100%', , , , 'fit-content']}
          justifyContent={['space-between', , , , 'flex-end']}
          style={{ gap: '16px' }}
        >
          {/* <Box display={['none', , , , 'block']}>
            <Text
              as={Link}
              href="https://apechain.calderaexplorer.xyz/address/0xef10741474d89110ec955dbc5cab04abc9c8d3be"
              external
              noWrap
              textAlign="center"
            >
              Contract $MDF
            </Text>
          </Box>
          <Box display={['none', , , , 'block']}>
            <Text as={Link} href="https://www.geckoterminal.com/" external noWrap textAlign="center" mb="0">
              Chart
            </Text>
          </Box> */}

          {/* <Box display={['block', , , , 'none']}>
            <Text
              as={Link}
              href="https://apechain.calderaexplorer.xyz/address/0xef10741474d89110ec955dbc5cab04abc9c8d3be"
              external
              noWrap
              textAlign="center"
              color="var(--color-white)"
            >
              Contract $MDF
            </Text>
          </Box>
          <Box display={['block', , , , 'none']}>
            <Text
              as={Link}
              href="https://www.geckoterminal.com/"
              external
              noWrap
              textAlign="center"
              mb="0"
              color="var(--color-white)"
            >
              Chart
            </Text>
          </Box> */}
        </Flex>

        <Box display={['block', , , , 'none']} width="100%">
          <Flex justifyContent="space-between" mb="8px">
            <Flex alignItems="center">
              <CurrencyLogo currency={ETHER} size="28px" />
              <Text fontSize="20px" color="var(--color-white)">
                {ETHER.symbol}
              </Text>
            </Flex>
            {fetchStatus !== FetchStatus.Fetched || !account ? (
              <Skeleton height="22px" width="60px" />
            ) : (
              <Text fontSize="20px" textAlign="center" ellipsis color="var(--color-white)">
                {formatBigNumber(balance, 6) || 0}{' '}
              </Text>
            )}
          </Flex>

          <Flex justifyContent="space-between">
            <Flex alignItems="center">
              <CurrencyLogo currency={tokens.cake} size="28px" />
              <Text fontSize="20px" color="var(--color-white)">
                {tokens.cake.symbol}
              </Text>
            </Flex>

            {cakeFetchStatus !== FetchStatus.Fetched || !account ? (
              <Skeleton height="22px" width="60px" />
            ) : (
              <Text fontSize="20px" textAlign="center" ellipsis color="var(--color-white)">
                {getFullDisplayBalance(cakeBalance, 18, 2) || 0}{' '}
              </Text>
            )}
          </Flex>
        </Box>

        <Box display={['none', , , , 'block']}>
          <SelectNetwork />
        </Box>

        <UserMenu />
      </HeaderButtons>
    </MainHeader>
  )
}

const MainHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-height: 80px;
  padding: 10px 36px;
  position: relative;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 10px;
  }
`

const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column-reverse;

  grid-gap: 16px;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
  }
`

// const sleep = (time) => {
//   return new Promise<void>((resolve) => {
//     setTimeout(() => {
//       resolve()
//     }, time)
//   })
// }
