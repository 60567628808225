import React, { useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import { ETHER } from '@meme-dex/sdk'
import { Box, Flex } from '../../../../components/Box'
import { UserMenuProps, variants } from './types'
// import MenuIcon from './MenuIcon'
import { UserMenuItem } from './styles'
import { Text } from '../../../../components/Text'
import useOnClickOutside from '../../../../hooks/useOnClickOutside'
import { useMatchBreakpoints } from '../../../../hooks'

export const StyledFlex = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  position: relative;
  max-width: 100%;
  width: 100%;
  min-height: auto;

  background: var(--color-white);
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 1);
  border-radius: 10px;

  ${({ theme }) => theme.mediaQueries.lg} {
    max-width: 244px;
    min-height: 54px;
    box-shadow: 0px 1px 0px 2px rgba(0, 0, 0, 1);
    border-radius: 20px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    transform: translateY(100%);

    height: 25px;
    background: transparent;
  }
`

export const StyledUserMenu = styled(Flex).attrs({
  justifyContent: ['space-between', , , , 'center'],
  width: '100%',
  alignItems: 'center',
})`
  position: relative;
  cursor: pointer;
  padding: 0 0 0 0;
  gap: 4px;
`

export const LabelText = styled(Text).attrs({ fontSize: ['20px'], pl: ['0', , , , '0'] })``

const Wrapper = styled(Flex)`
  position: relative;
  flex-shrink: 0;

  img {
    &.chain-logo {
      width: 33px;
      border-radius: 50%;
      border: 1px solid var(--color-black);
    }
  }
`

const Menu = styled.div<{ isOpen: boolean }>`
  background: var(--color-white);
  box-shadow: none;

  border-radius: 20px;
  pointer-events: auto;
  width: 325px;
  visibility: visible;
  z-index: 1001;
  display: none;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: block;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    box-shadow: 0px 1px 0px 2px rgba(0, 0, 0, 1);
  }

  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}
  ${UserMenuItem}:first-child {
    border-radius: 8px 8px 0 0;

    &:hover {
      background: transparent;
      opacity: 0.65;
    }
  }

  ${UserMenuItem}:last-child {
    border-radius: 0 0 8px 8px;

    &:hover {
      background: transparent;
      opacity: 0.65;
    }
  }
`

const ChainLogoWrapper = styled.div`
  position: absolute;
  bottom: -2px;
  right: -2px;
`

const UserMenu: React.FC<UserMenuProps> = ({
  account,
  text,
  avatarSrc,
  variant = variants.DEFAULT,
  children,
  bnbBalance,
  handleChangeSwitch,
  isWrongNetwork,
  explore,
  isShow,
  ...props
}) => {
  const { isMobile } = useMatchBreakpoints()
  const [isOpen, setIsOpen] = useState(false)
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null)
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null)
  const accountEllipsis =
    account && !isMobile
      ? `${account?.substring(0, 8)}...${account?.substring(account?.length - 8)}`
      : `${account?.substring(0, 5)}...${account?.substring(account?.length - 5)}`
  const { styles, attributes, update } = usePopper(targetRef, tooltipRef, {
    strategy: 'absolute',
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
  })

  const handleToggle = () => {
    if (isShow && update) {
      update()
    }
    setIsOpen((s) => !s)
  }

  useOnClickOutside({ current: targetRef }, () => setIsOpen(false))

  return (
    <>
      {isWrongNetwork ? (
        <StyledFlex ref={setTargetRef} onClick={handleChangeSwitch} {...props}>
          <Text fontSize="20px" ellipsis>
            Switch Network
          </Text>
        </StyledFlex>
      ) : (
        <StyledFlex ref={setTargetRef} {...props}>
          <StyledUserMenu>
            <Flex
              onClick={handleToggle}
              justifyContent={['flex-start', , , , 'center']}
              width="100%"
              pl={['8px', , , , '0']}
            >
              <Flex flex="0 0 auto" position="relative">
                <img width={24} className="chain-logo" src="/images/metamask-pink.svg" alt="" />
                <ChainLogoWrapper>
                  <img width={12} className="chain-logo" src="/images/chain/ape-chain.svg" alt="" />
                </ChainLogoWrapper>
              </Flex>
              <LabelText title={text || account}>{text || accountEllipsis}</LabelText>
            </Flex>

            <Box display={['block', , , , 'none']} zIndex={9}>
              {explore}
            </Box>
            {/* <Text fontSize="16px" ellipsis>
              {bnbBalance || 0}
            </Text>

            {ETHER.symbol} */}

            {/* <Wrapper>
              <img className="chain-logo" src="/images/chain/morph-icon.jpg" alt="" />
            </Wrapper> */}
          </StyledUserMenu>
          <Menu style={styles.popper} ref={setTooltipRef} {...attributes.popper} isOpen={isOpen}>
            <Box>{children?.({ isOpen, handleToggle })}</Box>
          </Menu>
        </StyledFlex>
      )}
    </>
  )
}

export default UserMenu
