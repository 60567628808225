import React from 'react'
import styled from 'styled-components'
import Flex from '../../components/Box/Flex'
import { Box } from '../../components/Box'
import { ArrowBackIcon, CloseIcon } from '../../components/Svg'
import { IconButton } from '../../components/Button'
import { ModalProps } from './types'

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  display: flex;
  padding: 12px 24px;
`

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
  color: var(--primary-color);
`

export const ModalBody = styled(Flex)`
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
`

const StyledIconButton = styled(IconButton)`
  width: 25px !important;
  height: 25px;
  border-radius: 50px;
  border: 1px solid black;
`

export const ModalCloseButton: React.FC<{ onDismiss: ModalProps['onDismiss'] }> = ({ onDismiss }) => {
  return (
    <StyledIconButton style={{ width: 'fit-content' }} variant="text" onClick={onDismiss} aria-label="Close the dialog">
      <CloseIcon color="var(--color-black)" />
    </StyledIconButton>
  )
}

export const ModalBackButton: React.FC<{ onBack: ModalProps['onBack'] }> = ({ onBack }) => {
  return (
    <IconButton style={{ width: 'fit-content' }} variant="text" onClick={onBack} area-label="go back" mr="8px">
      <ArrowBackIcon color="var(--color-black)" />
    </IconButton>
  )
}

export const ModalContainer = styled(Box)<{ minWidth: string }>`
  width: 100%;
  z-index: ${({ theme }) => theme.zIndices.modal};
  /* box-shadow: 0px 1px 0px 2px rgba(0, 0, 0, 1); */
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 1);
  border-radius: 16px;

  border: 1px solid var(--color-black);
  background: var(--color-white);
  position: relative;
`

export const Header = styled(Box).attrs({ maxWidth: ['442px'], width: '100%' })`
  position: absolute;
  z-index: 99;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-50% - 55px));
`
