import styled from 'styled-components'
import { Button, ButtonMenuItem } from 'packages/uikit'

const StyledButton = styled(Button)`
  min-height: 44px;
  white-space: nowrap;

  border: none;
  border-radius: 25px;
  padding: 0 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;

  font-weight: 700;
  background-color: var(--primary-color);
  color: var(--color-white);

  > img {
    width: 24px;
    height: 24px;
  }

  .dropdown-icon {
    width: 16px;
    height: 16px;
  }
`

const StyledActionButton = styled(Button)`
  position: relative;
  z-index: 1;
  white-space: nowrap;

  color: var(--color-white);
  background-color: var(--primary-color);
  font-weight: 800;

  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 44px;
  border-radius: 10px;
  border: none;
`

const ModalClearAllButton = styled(Button)`
  font-size: 14px;
  font-weight: 800;
  color: var(--color-black);
  opacity: 0.8;
  background: transparent;
  border: 0;
`

const TabButtonMenuItem = styled(ButtonMenuItem)`
  border-radius: 8px;
  background-color: ${({ theme, isActive }) => (isActive ? `${'var(--primary-color)'}` : `${'var(--color-black)'}`)};
  color: ${({ theme, isActive }) => (isActive ? `var(--color-white)` : `var(--color-white)`)};
  font-weight: 700 !important;

  :hover {
    color: var(--color-white);
  }
`

export { StyledButton, StyledActionButton, ModalClearAllButton, TabButtonMenuItem }
