import { BigNumber } from '@ethersproject/bignumber'
import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { ChainId } from '@meme-dex/sdk'
import { CHAIN_ID } from './networks'
import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const chainId = parseInt(CHAIN_ID, 10)
const { MAINNET } = ChainId

const serializedTokens = serializeTokens()

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('10000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const vaultPoolConfig = {
  [VaultKey.CakeVaultV1]: {
    name: <Trans>Auto ABS</Trans>,
    description: <Trans>Automatic restaking</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 1000000,
    tokenImage: {
      primarySrc: `/images/toggle/abswap.png`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.CakeVault]: {
    name: <Trans>Stake ABS</Trans>,
    description: <Trans>Stake, Earn – And more!</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 1000000,
    tokenImage: {
      primarySrc: `/images/svgs/logo.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
} as const

const pools: SerializedPoolConfig[] =
  chainId === MAINNET
    ? [
        {
          sousId: 0,
          stakingToken: serializedTokens.cake,
          earningToken: serializedTokens.cake,
          contractAddress: {
            33139: '0x54455532324468f5d60ebd6469f2bbd3dc47a7f8',
            195: '0xa7e8280b8CE4f87dFeFc3d1F2254B5CCD971E852',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '3.25',
          sortOrder: 1,
          isFinished: false,
        },
        // {
        //   sousId: 1,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.okcat,
        //   contractAddress: {
        //     33139: '0x41057040760cc9f04954e6043095f07d0ac2593e',
        //     195: '',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '385755899',
        //   sortOrder: 1,
        //   isFinished: false,
        // },
        // {
        //   sousId: 3,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.astro,
        //   contractAddress: {
        //     33139: '',
        //     195: '0xA0e9F28837325f5718AFE497e0B73fDDE99c3dA9',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.13',
        //   sortOrder: 3,
        //   isFinished: true,
        // },
        // {
        //   sousId: 4,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.swapz,
        //   contractAddress: {
        //     33139: '',
        //     195: '0xd6E00EA52eb7bdb5E15A8445Ea7e25c70Dc763a4',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.1286',
        //   sortOrder: 4,
        //   isFinished: true,
        // },
        // {
        //   sousId: 5,
        //   stakingToken: serializedTokens.swapz,
        //   earningToken: serializedTokens.swapz,
        //   contractAddress: {
        //     33139: '',
        //     195: '0x0b7c46E1f729972499a9Ab45D307101198Ab9BA9',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.239',
        //   sortOrder: 4,
        //   isFinished: true,
        // },
        // {
        //   sousId: 6,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.vdgt,
        //   contractAddress: {
        //     33139: '',
        //     195: '0xbedeBB4c7D336E6E5e5B8685d33Fb3bbfebc4B6B',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.317',
        //   sortOrder: 5,
        //   isFinished: true,
        //   enableEmergencyWithdraw: true,
        // },
        // {
        //   sousId: 7,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.wvlx,
        //   contractAddress: {
        //     33139: '',
        //     195: '0xe68a572CEc1C021d8a13DbDd0bDBCFEdc8F1901f',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.03145',
        //   sortOrder: 6,
        //   isFinished: true,
        // },
        // {
        //   sousId: 8,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.vlxpad,
        //   contractAddress: {
        //     33139: '',
        //     195: '0x2ABe5242d36E3A0f643EF4748d35Bd8B7FC56C5d',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.0629',
        //   sortOrder: 7,
        //   isFinished: true,
        // },
      ]
    : [
        {
          sousId: 0,
          stakingToken: serializedTokens.cake,
          earningToken: serializedTokens.cake,
          contractAddress: {
            33139: '',
            195: '0x87d80839E3d5D435D67346761C0a6c749Cb7A6a4',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '1',
          sortOrder: 1,
          isFinished: false,
        },
        {
          sousId: 1,
          stakingToken: serializedTokens.usdc,
          earningToken: serializedTokens.cake,
          contractAddress: {
            33139: '',
            195: '0x7aE4B7DA842d528d1AfBAF7Da9b820CC76863521',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '1',
          sortOrder: 1,
          isFinished: true,
        },
        {
          sousId: 2,
          stakingToken: serializedTokens.usdc,
          earningToken: serializedTokens.cake,
          contractAddress: {
            33139: '',
            195: '0xD229b6EC31a3eCF05D46F00F05aF63C759f52a09',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '0.072',
          sortOrder: 2,
          isFinished: true,
        },
        {
          sousId: 3,
          stakingToken: serializedTokens.usdc,
          earningToken: serializedTokens.cake,
          contractAddress: {
            33139: '',
            195: '0x77D66E223836f1b7D3cD928539ED919e5C2a4C99',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '0.072',
          sortOrder: 3,
          isFinished: false,
        },
      ]
export default pools
