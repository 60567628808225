import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 35 35" {...props}>
      <path
        d="M17.5 0C7.83475 0 0 7.83475 0 17.5C0 27.1652 7.83475 35 17.5 35C27.1652 35 35 27.1652 35 17.5C35 7.83475 27.1652 0 17.5 0ZM14 26.9745L5.76275 18.7372L8.23725 16.2628L14 22.0255L26.7627 9.26275L29.2373 11.7372L14 26.9745Z"
        fill="#5C760F"
      />
    </Svg>
  )
}

export default Icon
