import styled from 'styled-components'
import { Flex } from '../Box'

const StyledBottomNav = styled(Flex)`
  background: var(--color-white);
  position: fixed;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);

  border-radius: 20px;

  width: 100%;
  max-width: 85%;
  height: 60px;
  padding-bottom: env(safe-area-inset-bottom);

  html[data-useragent*='TokenPocket_iOS'] & {
    padding-bottom: 45px;
  }
  z-index: 20;
`

export default StyledBottomNav
