import styled from 'styled-components'
import { Text } from 'packages/uikit'

const MainText = styled(Text)`
  color: var(--color-black);

  &.opacity-08 {
    opacity: 0.8;
  }
`

const ControlLabel = styled(Text)`
  text-transform: capitalize;
  ${({ theme }) => theme.mediaQueries.md} {
    text-transform: uppercase;

    &.filter-by {
      margin-left: 26px;
    }
  }
`

export { MainText, ControlLabel }
